.ai_messageContainer {
  position: relative;
  border: 1px solid #FFFFFF;
}

.ai_actionContainer { 
  display: none;
  border: 1px solid #FFFFFF;
}

.ai_messageContainer:hover {
  border: 1px solid #6f6f6f;
}

.ai_messageContainer:hover .ai_actionContainer {
  display: block;
  position: absolute;
  top: -20px;
  right: 10px;
  padding: 5px 15px;
  border: 1px solid #6f6f6f;
  background-color: #FFFFFF;
  border-radius: 10px;
}